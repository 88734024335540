
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import TopPage from '@/components/templates/TopPage.vue'
import { ImageQuery } from '@/data/@types/ImageQuery'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    TopPage,
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const CONTENTS_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    if (location.hash) router.push('/')

    store.commit('SET_INPUT_KEYWORD', '')

    // TOP画面に表示するコレクション
    const collections = [
      'A00003', // 古典籍
      'A00001', // 図書
      'A00002', // 雑誌
      'A00015', // 官報
      'A00188', // 帝国議会資料
      'A00014', // 博士論文
      'A00017', // 憲政資料
      'A00016', // 日本占領関係資料
      'A00019', // プランゲ文庫
      'A00121', // 録音・映像関係資料
      'A00022', // 新聞
      'A00173', // 日系移民関係資料
      'A00122', // 他機関デジタル化資料
      'A00150', // 特殊デジタルコレクション
      'A00162', // パッケージ系電子出版物
      'A00152', // 地図
      'B00000', // 電子書籍・電子雑誌
      'A00148', // 内務省検閲発禁図書
    ]

    // モックに合わせて3つのデータを表示
    const imageQueries: ImageQuery[] = [
      {
        id: '1',
        thumbnail: CONTENTS_BASE_URL + 'static/images/image_search_samples/1.jpg',
      },
      {
        id: '2',
        thumbnail: CONTENTS_BASE_URL + 'static/images/image_search_samples/2.jpg',
      },
      {
        id: '3',
        thumbnail: CONTENTS_BASE_URL + 'static/images/image_search_samples/3.jpg',
      },
    ]

    return {
      collections,
      imageQueries,
    }
  },
})
