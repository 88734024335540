import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5eedcd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-list has-background-contents shadow" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "static-page link-underline-default" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "news-list-footer level-right is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('news.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", { innerHTML: _ctx.includeHtml }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: { name: 'Information' },
        class: "level is-mobile"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('news.previous')), 1),
          _createVNode(_component_DssIcon, { icon: "arrow-right" })
        ]),
        _: 1
      })
    ])
  ]))
}