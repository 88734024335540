import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-441190e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "level-left is-mobile app-label-places",
  "data-cy": "item-card-view-label"
}
const _hoisted_2 = {
  key: 2,
  class: "item-card-view-media"
}
const _hoisted_3 = {
  key: 0,
  class: "has-background-page level-center is-mobile"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 3,
  class: "item-card-view-media"
}
const _hoisted_6 = { class: "has-background-page level-center is-mobile" }
const _hoisted_7 = { class: "item-card-view-content level is-mobile" }
const _hoisted_8 = { class: "flex-grow" }
const _hoisted_9 = { class: "item-card-view-heading level is-mobile" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "has-text-table-cell-title" }
const _hoisted_12 = {
  key: 1,
  class: "has-text-table-cell-title",
  "data-cy": "item-card-view-title"
}
const _hoisted_13 = { class: "has-text-table-cell-title" }
const _hoisted_14 = { class: "item-card-view-description" }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_AppLabelPlaces = _resolveComponent("AppLabelPlaces")!
  const _component_AppImg = _resolveComponent("AppImg")!
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { class: "item-card-view has-background-contents" }, {
    default: _withCtx(() => [
      (_ctx.item.pid === 'about')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.spotlightItemLabel), 1))
        : (_openBlock(), _createBlock(_component_AppLabelPlaces, {
            key: 1,
            type: _ctx.permissionColor,
            class: "item-card-view-permission-type level-left is-mobile",
            "data-cy": "item-card-view-label"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppIconPlaces, { type: _ctx.permissionType }, null, 8, ["type"]),
              _createTextVNode(" " + _toDisplayString(_ctx.spotlightItemLabel), 1)
            ]),
            _: 1
          }, 8, ["type"])),
      (_ctx.item.pid === 'about')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.thumbnail)
              ? (_openBlock(), _createElementBlock("figure", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _ctx.thumbnail,
                    alt: `${_ctx.spotlightItemTitle}${_ctx.$t('spotlight.altSuffix')}`,
                    class: "app-img"
                  }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: `/${_ctx.item.pid}`,
              "aria-label": _ctx.$t('label.thumbnail')
            }, {
              default: _withCtx(() => [
                _createElementVNode("figure", _hoisted_6, [
                  (_ctx.spotlightItemThumbnail)
                    ? (_openBlock(), _createBlock(_component_AppImg, {
                        key: 0,
                        src: _ctx.spotlightItemThumbnail,
                        alt: `${_ctx.title}${_ctx.$t('spotlight.altSuffix')}`
                      }, null, 8, ["src", "alt"]))
                    : (_openBlock(), _createBlock(_component_PlaceholderImage, {
                        key: 1,
                        type: "book-open"
                      }))
                ])
              ]),
              _: 1
            }, 8, ["to", "aria-label"])
          ])),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.link && _ctx.item.pid === 'about')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.link,
                  "data-cy": "item-card-view-title"
                }, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.spotlightItemTitle), 1)
                ], 8, _hoisted_10))
              : (_ctx.item.pid === 'about')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.spotlightItemTitle), 1))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 2,
                    to: `/${_ctx.item.pid}`,
                    "aria-label": _ctx.$t('label.thumbnail'),
                    "data-cy": "item-card-view-title"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.spotlightItemTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["to", "aria-label"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("p", null, _toDisplayString(_ctx.item.description), 1)
      ]),
      _createElementVNode("p", {
        class: "item-card-view-quotes has-text-contents-normal",
        innerHTML: _ctx.context[_ctx.lang]
      }, null, 8, _hoisted_15)
    ]),
    _: 1
  }))
}